import React from 'react';

// services
import AuthService from 'services/auth';
// components
import { Menu, Dropdown, Avatar } from 'antd';
import {
  EditOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

// hooks
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useLocalStorage from 'hooks/use-local-storage';

// types
import { LocalStorageEnum, AppRoutes } from 'types/enumes';

const HeaderProfile: React.FC = () => {
  const history = useHistory();
  const { removeItem } = useLocalStorage();

  const user = AuthService.getUser();

  const handleLogOut = async () => {
    await AuthService.logOut();
    window.location.href = '/login';
  };

  const { t } = useTranslation<string>();
  const profileImg = '/images/avatar.png';

  const profileMenu = (
    <div className="nav-profile nav-dropdown order-0">
      <div className="nav-profile-header">
        <div className="d-flex justify-content-around">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">
              {user.firstName}
              {' '}
              {user.lastName}
            </h4>
            {/* <span className="text-muted">Tester</span> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item>
            <a href="/profile/edit">
              <SettingOutlined className="mr-3" />
              <span className="font-weight-normal">{t('header_profile_settings')}</span>
            </a>
          </Menu.Item>
          <Menu.Item>
            <div className="" onClick={handleLogOut} aria-hidden="true">
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">{t('header_profile_log_out')}</span>
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} style={{ background: '#efefef' }} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default HeaderProfile;
