import React, { ReactElement } from 'react';

// components
import { Alert as AllertMessage } from 'antd';
import { IconType } from 'antd/lib/notification';
import {
  DesktopOutlined,
  InboxOutlined,
  MailOutlined,
  PieChartOutlined,
  FormOutlined,
  BarsOutlined,
  MessageOutlined,
  BookOutlined,
  StarOutlined,
  ReadOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  ProfileOutlined,
  TagOutlined,
  TagsOutlined,
  KeyOutlined,
  GroupOutlined,
  AccountBookOutlined,
  FolderOutlined,
  FolderViewOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined,
  UserOutlined,
  SafetyOutlined,
  RocketOutlined,
  SettingOutlined,
  GiftOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LikeOutlined,
  DislikeOutlined,
  PrinterOutlined,
  RestOutlined,
  ToolOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';

// styles
import { WrapperIconsStyled } from './style';

// types
type Props = {
  readonly icon: string | null,
  readonly name?: string,
}

const icons: { [key: string]: React.ReactElement } = {
  FormOutlined: <FormOutlined />,
  BarsOutlined: <BarsOutlined />,
  MessageOutlined: <MessageOutlined />,
  BookOutlined: <BookOutlined />,
  StarOutlined: <StarOutlined />,
  ReadOutlined: <ReadOutlined />,
  ShoppingOutlined: <ShoppingOutlined />,
  SolutionOutlined: <SolutionOutlined />,
  ProfileOutlined: <ProfileOutlined />,
  TagOutlined: <TagOutlined />,
  TagsOutlined: <TagsOutlined />,
  KeyOutlined: <KeyOutlined />,
  GroupOutlined: <GroupOutlined />,
  AccountBookOutlined: <AccountBookOutlined />,
  FolderOutlined: <FolderOutlined />,
  FolderViewOutlined: <FolderViewOutlined />,
  VideoCameraOutlined: <VideoCameraOutlined />,
  VideoCameraAddOutlined: <VideoCameraAddOutlined />,
  UserOutlined: <UserOutlined />,
  SafetyOutlined: <SafetyOutlined />,
  PieChartOutlined: <PieChartOutlined />,
  DesktopOutlined: <DesktopOutlined />,
  InboxOutlined: <InboxOutlined />,
  MailOutlined: <MailOutlined />,
  ExclamationCircleOutlined: <ExclamationCircleOutlined />,
  RocketOutlined: <RocketOutlined />,
  GiftOutlined: <GiftOutlined />,
  SettingOutlined: <SettingOutlined />,
  RestOutlined: <RestOutlined />,
  ToolOutlined: <ToolOutlined />,
  EditOutlined: <EditOutlined />,
  DeleteOutlined: <DeleteOutlined />,
  LikeOutlined: <LikeOutlined />,
  DislikeOutlined: <DislikeOutlined />,
  PrinterOutlined: <PrinterOutlined />,
  SearchOutlined: <SearchOutlined />,
  FileExcelOutlined: <FileExcelOutlined />,
};

const Icons: React.FC<Props> = (props: Props): ReactElement | null => {
  const {
    icon,
    name,
  } = props;

  if (icon === null) {
    return (
      <WrapperIconsStyled>
        {/* {name?.charAt(0)} */}
        {name}
      </WrapperIconsStyled>
    );
  }

  if (icon !== null) {
    return icons[icon];
  }

  return null;
};

export default Icons;
