import React from 'react';

// components
import { Spin } from 'antd';

type Props = {
  readonly ifOpen: boolean;
  readonly message?: string;
}

const Loader: React.FC<Props> = (props: Props) => {
  const { ifOpen, message } = props;

  return (
    <div>
      { ifOpen ? (
        <div className="loader-bg">
          <div className="loader">
            <Spin size="large" />
            {message && <p className="mt-2">{message}</p>}
          </div>
        </div>
      ) : null }
    </div>
  );
};

export default Loader;
