export class FiltersService {
  public static filtersPathPrefix = '_filters_';
  public static filtersPaginationLSName = '_filters_pagination_number';
  public static initFilter(path: string, filter: any): void {
    window.localStorage.setItem(
        `${FiltersService.filtersPathPrefix}${path}_${filter.filterType}_${filter.filterId}`,
        // `${path}_${filter.filterType}_${filter.filterId}`,
        JSON.stringify(filter),
    );
  }

  public static getFilter(path: string) {
    if(!path.startsWith(FiltersService.filtersPathPrefix)){
      path = FiltersService.filtersPathPrefix + path;
    }

    if (window.localStorage.getItem(path)) {
      return JSON.parse(window.localStorage.getItem(path) as string);
    }

    if (!window.localStorage.getItem(path)) {
      return null;
    }

    return null;
  }

  // public static setFilter(filter: any): void {
  //   if (FiltersService.getFilter(filter.filterPath)) {
  //     const itemNamePath = FiltersService.getFilter(filter.filterPath);
  //     window.localStorage.removeItem(itemNamePath.filterPath);
  //
  //     window.localStorage.setItem(
  //         filter.filterPath,
  //         JSON.stringify(filter),
  //     );
  //   }
  // }

  public static setFilter(filter: any): void {
    if (FiltersService.getFilter(filter.filterPath)) {
      const filterPathName = FiltersService.filtersPathPrefix + filter.filterPath;
      window.localStorage.removeItem(filterPathName);
      window.localStorage.setItem(
          filterPathName,
          JSON.stringify(filter),
      );
    }
  }

  // public static removeFilter(path: string): void {
  //   window.localStorage.removeItem(path);
  // }
  public static removeFilter(path: string): void {
    path = FiltersService.filtersPathPrefix + path;
    window.localStorage.removeItem(path);
  }

  // public static createUrlForAllSelectedFilters(currentLocation = '', url: string | null): string {
  //   let prepareUrl = url || `${currentLocation}?`;
  //
  //   Object.keys(window.localStorage).forEach((item) => {
  //     if (item.startsWith(currentLocation)) {
  //       prepareUrl += `${FiltersService.getFilter(item).filterUrl}&`;
  //     }
  //   });
  //
  //   const modifiedUrl = prepareUrl.slice(0, -1);
  //   return modifiedUrl;
  //
  // }

  public static createUrlForAllSelectedFilters(currentLocation = '', url: string | null): string {
    let prepareUrl = url || `${currentLocation}?`;

    Object.keys(window.localStorage)
        .forEach((item) => {
          if (item.startsWith(FiltersService.filtersPathPrefix + currentLocation)) {
            prepareUrl += `${FiltersService.getFilter(item).filterUrl}&`;
          }
        });

    return prepareUrl.slice(0, -1);
  }

  public static removeAllFilters(): void {
    Object.keys(window.localStorage)
        .forEach((item) => {
          if (item.startsWith(FiltersService.filtersPathPrefix)) {
            window.localStorage.removeItem(item);
          }
        });
  }
}
