import React from 'react';

// components
import { Layout } from 'antd';

const FooterLayout: React.FC = () => (
  <Layout.Footer className="footer m-0" />
);

export default FooterLayout;
