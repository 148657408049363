import { IToken, IUser } from 'types/core';
import APIService from "../api";
import {ApiRoutes} from "../../types/enumes";

export class AuthService {
  public static getTokens(): IToken | null {
    const userString = window.localStorage.getItem('user');
    if (userString) {
      const objectUser = JSON.parse(window.localStorage.getItem('user') || 'Token is not exist');
      return {
        accessToken: objectUser.accessToken,
        refreshToken: objectUser.refreshToken,
      };
    }

    return null;
  }

  public static removeExpiresRefreshToken(): void {
    window.localStorage.removeItem('expires');
  }

  public static expiresRefreshToken(): void {
    window.localStorage.setItem('expires', 'true');
  }

  public static checkIfExpiresRefreshToken(): boolean {
    if (window.localStorage.getItem('expires')) {
      return true;
    }

    return false;
  }

  public static setUser(user: any): void {
    const stringifyUser = JSON.stringify(user);
    window.localStorage.setItem('user', stringifyUser);
  }

  public static getUser(): IUser {
    return JSON.parse(window.localStorage.getItem('user') || 'Token is not exist');
  }

  public static logIn(dataApi: any): void {
    const user = {
      firstName: dataApi.data.admin.firstName,
      lastName: dataApi.data.admin.lastName,
      accessToken: dataApi.data.accessToken,
      refreshToken: dataApi.data.refreshToken,
    };

    this.removeExpiresRefreshToken();
    this.setUser(user);
  }

  public static logOut = async(): Promise<void> => {
    const tokens = this.getTokens();
    const data = {refreshToken: tokens?.refreshToken};

    await APIService.post<any>(ApiRoutes.LOGOUT, data)
        .finally(() => {
          this.removeExpiresRefreshToken();
          window.localStorage.removeItem('user');
        })
    ;
  }

  public static isLogged():boolean {
    const token: {accessToken: string, refreshToken: string } | null = {
      accessToken: '',
      refreshToken: '',
    };

    if (window.localStorage.getItem('user')) {
      const objectUser = JSON.parse(window.localStorage.getItem('user') || 'Token is not exist');
      token.accessToken = objectUser.accessToken;
      token.refreshToken = objectUser.refreshToken;
    }

    if (token.accessToken && token.refreshToken !== '') {
      return true;
    }

    return false;
  }
}
