import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// json
import en from './locales/en/en.json';
import pl from './locales/pl/pl.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'pl',
  debug: true,
  resources: {
    en: {
      translation: en,
    },
    pl: {
      translation: pl,
    },
  },
});

export default i18n;
