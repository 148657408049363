import React from 'react';

// components
import { Layout } from 'antd';
import Header from 'views/layouts/header';
import Content from 'views/layouts/content';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from 'hooks/use-local-storage';

// selector
import { getIsMenuCollapsed } from 'redux/selectors';

// store
import { setLanguage } from 'redux/slices/configSlice';

// types
import { LocalStorageEnum } from 'types/enumes';

// mocked
import { appSettings } from 'assets/mocked-data';

const Layouts: React.FC = () => {
  const isMenuCollapsed: boolean = useSelector(getIsMenuCollapsed);

  const { getItem } = useLocalStorage();
  const storedLang: string = appSettings.languages.filter((lang) => lang === getItem(LocalStorageEnum.APP_LANGUAGE))[0];

  const dispatch = useDispatch();
  dispatch(setLanguage(storedLang));

  return (
    <Layout className={`${isMenuCollapsed ? 'menu-is-close' : 'menu-is-open'}`}>
      <Header />
      <Layout className="app-container">
        <Content />
      </Layout>
    </Layout>
  );
};

export default Layouts;
