/* eslint-disable import/prefer-default-export */
import { configureStore } from '@reduxjs/toolkit';

import configReducer from 'redux/slices/configSlice';
import dataTableReducer from 'redux/slices/dataTableSlice';
import themeReducer from 'redux/slices/themeSlice';

export const store = configureStore({
  reducer: {
    config: configReducer,
    dataTableState: dataTableReducer,
    theme: themeReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
