import React from 'react';

// components
import { Link } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import SlotBuilder from 'views/components/slot-builder';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// store
import { toggleMenuCollapsing } from 'redux/slices/configSlice';
import { getIsMenuCollapsed, selectSlotConfigurationById, getIfThemeIsDark } from 'redux/selectors';

// types
import { ISlot } from 'types/core';
import { IStore } from 'types/store';

// mocked
import slotsInjectableComponents from 'injectableComponents';
import HeaderProfile from 'views/components/header-profile';
import HeaderSettings from 'views/components/header-settings/';
import { ThemeService } from 'services/theme/ThemeService';
import { RootState } from 'redux';

const Header: React.FC = () => {
  const isMenuCollapsed: boolean = useSelector(getIsMenuCollapsed);
  const slotConfig: ISlot = useSelector((state: IStore) => selectSlotConfigurationById(state, 'topRight'));

  const dispatch = useDispatch();

  const toggleCollapsed = (): void => {
    dispatch(toggleMenuCollapsing(!isMenuCollapsed));
  };

  return (
    <Layout.Header className="app-header">
      <div className="app-header-wrapper">
        <div className="logo header-logo-width">
          <Link to="/">
            <img
              src={useSelector(getIfThemeIsDark) ? '/images/main-logo.svg' : '/images/main-logo.svg'}
              alt="quantoo"
              style={{ width: isMenuCollapsed ? '50px' : '150px', maxHeight: '60px' }}
            />
          </Link>
        </div>
        <div className="nav align-items-center header-width">
          <div className="nav-left">
            <div className="ant-menu ant-menu-root ant-menu-horizontal border-0">
              <div className="ant-menu-item-only-child">
                <Button
                  shape="circle"
                  onClick={toggleCollapsed}
                  icon={isMenuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              {/* <SlotBuilder
                config={slotConfig}
                components={slotsInjectableComponents}
              /> */}
              <HeaderProfile />
              <HeaderSettings />
            </div>
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
