import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, useHistory, Redirect,
} from 'react-router-dom';

import AuthService from 'services/auth';

// types
import { IRoutes } from 'types/core';
import { AppRoutes } from 'types/enumes';

// components
import AppView from 'views/AppView';
import Loader from 'views/components/loader';

// dynamic components
const Login = React.lazy(() => import('views/pages/login-page'));
const RecoverPassword = React.lazy(() => import('views/pages/recover-password-page'));
const SetNewPassword = React.lazy(() => import('views/pages/new-password-page'));
const NotFound = React.lazy(() => import('views/pages/not-found-page'));

const Routes: React.FC = () => {
  const history = useHistory();

  const config: IRoutes[] = [
    { exact: true, path: AppRoutes.APP_ROOT, component: () => <Redirect to={AppRoutes.LOGIN} /> },
    { exact: true, path: AppRoutes.LOGIN, component: Login },
    { exact: true, path: AppRoutes.RECOVERY_PASSWORD_TOKEN, component: SetNewPassword },
    { exact: true, path: AppRoutes.RECOVERY_PASSWORD, component: RecoverPassword },
    { exact: false, path: '*', component: NotFound },
  ];

  return (
    <React.Suspense fallback={<Loader ifOpen={false} />}>
      <Router>
        <Switch>
          {
            config.map((item: IRoutes, index: number) => (
              <Route
                exact={item.exact}
                key={index.toString()}
                path={item.path}
                component={item.component}
              />
            ))
          }
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default Routes;
