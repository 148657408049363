import React from 'react';

// services
import ThemeService from 'services/theme';

// hooks
import { useDispatch } from 'react-redux';

// redux actions
import { toggleTheme } from 'redux/slices/themeSlice';

// slice
import { getAppConfiguration } from 'redux/slices/configSlice';

// components
import Layouts from 'views/layouts/layouts';

const AppView: React.FC = () => {
  const dispatch = useDispatch();

  if (!ThemeService.getTheme()) {
    ThemeService.initTheme('light');
  }

  React.useEffect(() => {
    dispatch(getAppConfiguration());
    dispatch(toggleTheme(ThemeService.getTheme()));
  }, [dispatch]);

  return (
    <React.Suspense fallback={null}>
      <Layouts />
    </React.Suspense>
  );
};

export default AppView;
