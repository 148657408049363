import React from 'react';
import { useHistory } from 'react-router-dom';
// services
import AuthService from 'services/auth';
import ThemeService from 'services/theme';

// components
import { Switch } from 'antd';
import LanguageConfiguration from 'views/components/language-configuration';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useTranslation } from 'react-i18next';

// store
import { getTheme, getIsMenuCollapsed, getLanguageIsChangeable } from 'redux/selectors';
import { toggleMenuCollapsing } from 'redux/slices/configSlice';

// redux actions
import { toggleTheme } from 'redux/slices/themeSlice';

// types
import { ThemeTypesEnum } from 'types/enumes';
import { RootState } from 'redux';

const ThemeConfigurator: React.FC = () => {
  const isMenuCollapsed: boolean = useSelector(getIsMenuCollapsed);
  const languageIsChangeable: boolean = useSelector(getLanguageIsChangeable);

  const history = useHistory();

  const { t } = useTranslation<string>();
  const { switcher } = useThemeSwitcher();
  const dispatch = useDispatch();
  const currentTheme = useSelector((state: RootState) => state.themeState);

  const toggleCollapsed = (): void => {
    dispatch(toggleMenuCollapsing(!isMenuCollapsed));
  };

  const handleSwitchTheme = (arg: boolean): void => {
    if (arg === true) {
      dispatch(toggleTheme(ThemeTypesEnum.DARK));
      ThemeService.setTheme(ThemeTypesEnum.DARK);
      switcher({ theme: ThemeService.getTheme() });
    }

    if (arg === false) {
      dispatch(toggleTheme(ThemeTypesEnum.LIGHT));
      ThemeService.setTheme(ThemeTypesEnum.LIGHT);
      switcher({ theme: ThemeService.getTheme() });
    }
  };

  return (
    <>
      <div className="mb-5">
        <h4 className="mb-3 font-weight-bold">{t('settings_nav_title')}</h4>
        <div className="my-4 d-flex align-items-center justify-content-between">
          <div>{`${t('settings_nav_collapse')}:`}</div>
          <div>
            <Switch
              checked={isMenuCollapsed}
              onChange={() => toggleCollapsed()}
            />
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h4 className="mb-3 font-weight-bold">{t('settings_theme_titile')}</h4>
        <div className="my-4 d-flex align-items-center justify-content-between">
          <div>{`${t('settings_thehe_mode')}:`}</div>
          <div>
            <Switch
              checked={ThemeService.getTheme() === 'dark'}
              onChange={handleSwitchTheme}
            />
          </div>
        </div>
      </div>
      {languageIsChangeable ? (
        <div className="mb-5">
          <h4 className="mb-3 font-weight-bold">{t('settings_locale_titile')}</h4>
          <div className="my-4 d-flex align-items-center justify-content-between">
            <div>{`${t('settings_locale_lang')}:`}</div>
            <div>
              <LanguageConfiguration />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ThemeConfigurator;
