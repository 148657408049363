import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { BrowserRouter as Router } from 'react-router-dom';

// services
import AuthService from 'services/auth';
import ThemeService from 'services/theme';

// components
import AppView from 'views/AppView';

// routes
import { Routes as UnAuthenticated } from './core/routes';

// import { Switch } from 'antd';
import { THEMES } from './core/appConfig';

const App: React.FC = () => (
  <Router>
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={THEMES}
        defaultTheme={ThemeService.getTheme()}
        insertionPoint="styles-insertion-point"
      >
        {
          AuthService.isLogged() ? <AppView /> : <UnAuthenticated />
        }
      </ThemeSwitcherProvider>
    </div>
  </Router>
);

export default App;
