// types;
import {ILocalesItem} from 'types/core';

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const API_HOST = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_PROD}` : `${process.env.REACT_APP_DEV}`;
export const IS_DEV = process.env.NODE_ENV !== 'production';

export const THEMES = {
  dark: '/css/dark-theme.css',
  light: '/css/light-theme.css',
};

export const LANGUAGES: ILocalesItem[] = [
  { name: 'Polish', code: 'pl' },
  { name: 'English', code: 'en' },
];

export const AVAILABLE_EXTENSIONS_IMAGE: string[] = [
  'jpg',
  'jpeg',
  'png',
];

export const AVAILABLE_EXTENSIONS_PDF: string[] = [
  'pdf',
];
