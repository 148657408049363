/* eslint-disable no-param-reassign */
import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';

// types
import { IThemeSlice } from 'types/store';
import { ThemeTypesEnum } from 'types/enumes';

const THEME_SLICE_NAME = 'THEME_SLICE_NAME';

const initialState: IThemeSlice = {
  currentTheme: ThemeTypesEnum.LIGHT,
};

export const themeSlice = createSlice({
  name: THEME_SLICE_NAME,
  initialState,
  reducers: {
    toggleTheme: (state: IThemeSlice, action: PayloadAction<ThemeTypesEnum>) => {
      state.currentTheme = action.payload;
    },
  },
});

export const {
  toggleTheme,
} = themeSlice.actions;
export default themeSlice.reducer;
