import { ThemeTypesEnum } from 'types/enumes';

export class ThemeService {
  public static initTheme(initTheme: string): void {
    if (!window.localStorage.getItem('theme')) {
      window.localStorage.setItem('theme', JSON.stringify(initTheme));
    }
  }

  public static getTheme() {
    const theme = JSON.parse(window.localStorage.getItem('theme'));
    return theme === 'dark' ? ThemeTypesEnum.DARK : ThemeTypesEnum.LIGHT;
    // return theme;
  }

  public static setTheme(theme: string): void {
    const stringifyTheme = JSON.stringify(theme);
    window.localStorage.setItem('theme', stringifyTheme);
  }
}
