/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// services
import AuthService from 'services/auth';

// Base API host
import { API_HOST } from 'core/appConfig';

const apiInstance = axios.create({
  baseURL: API_HOST,
  transformRequest(data) {
    return JSON.stringify(data);
  },
});

/**
 * Adding token to every request
 */
apiInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    config.headers = {
      Authorization: `Bearer ${AuthService.getTokens()?.accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (config.url?.includes('/auth/login') || config.url?.includes('/recover-password') || config.url?.includes('/reset-password')) {
      delete config.headers.Authorization;
    }

    return config;
  },
  (error: any) => {
    Promise.reject(error.response.data.errors);
  },
);

/**
 * Handle token expivation
 */
apiInstance.interceptors.response.use(
  (response) => {
    const editedResponse = response;

    if (editedResponse.headers['content-type'].includes('csv')) {
      editedResponse.data = {
        grid: null,
        type: null,
        file: editedResponse.data,
      };
    }

    return editedResponse;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      await axios.post(`${API_HOST}auth/refresh-token`, {
        refreshToken: AuthService.getUser().refreshToken,
      })
        .then((res) => {
          AuthService.logIn(res.data);
        })
        .catch(() => {
          AuthService.expiresRefreshToken();
        });
      return apiInstance(originalRequest);
    }

    return Promise.reject(error.response.data.errors);
  },
);
export {
  apiInstance,
};
