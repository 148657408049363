import { createSelector } from 'reselect';

// types
import { IStore } from 'types/store';
import { ISlot, IMenuGroup } from 'types/core';
import { ThemeTypesEnum } from 'types/enumes';

/**
 * Config slice`s selectors
 */

export const getIfThemeIsDark = (state: IStore): boolean => state.theme.currentTheme === ThemeTypesEnum.DARK;
export const getTheme = (state: IStore): any => state.theme.currentTheme;
export const getIsMenuCollapsed = (state: IStore): boolean => state.config.isMenuCollapsed;
export const getLanguage = (state: IStore): string => state.config.currentLanguage;
export const getMenuItems = (state: IStore): IMenuGroup[] => state.config.menu;
export const getLanguageIsChangeable = (state: IStore): boolean => state.config.settings.changeLanguage;

export const getSlotsConfiguration = (state: IStore): ISlot[] => state.config.slots;

export const selectSlotConfigurationById = createSelector(
  getSlotsConfiguration,
  (_: IStore, id: string) => id,
  (slots: ISlot[], id: string) => slots.filter((slot: ISlot) => slot.id === id).pop() || {} as ISlot,
);
