import React, { useState } from 'react';

// components
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from 'views/components/theme-configuration';

// hooks
import { useTranslation } from 'react-i18next';

const HeaderSettings: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation<string>();

  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={() => setVisible(true)}>
          <SettingOutlined className="nav-icon d-inline-block mt-4 pt-2" />
        </Menu.Item>
      </Menu>
      <Drawer
        title={t('settings_title')}
        width={300}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  );
};

export default HeaderSettings;
