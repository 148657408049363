import React from 'react';

// components
import { Menu as NavMenu } from 'antd';

// hooks
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useMenuBuilder from 'hooks/use-menu-builder';

// store
import { getIsMenuCollapsed, getMenuItems } from 'redux/selectors';

// types
import { IMenuGroup } from 'types/core';
import FiltersService from "../../../services/filters";

const Menu: React.FC = () => {
  const isMenuCollapsed: boolean = useSelector(getIsMenuCollapsed);
  const menuItems: IMenuGroup[] = useSelector(getMenuItems);
  const { buildMenu } = useMenuBuilder();
  const history = useHistory();

  const [openSubMenu, setOpenSubMenu] = React.useState<any>();
  const [selectedItemMenu, setSelectedItemMenu] = React.useState<any>();

  const onChangeNavTab = (path, array) => {
    FiltersService.removeAllFilters();
    let item = array.item.props.elementRef.current.baseURI;
    window.location.href = item;
    setSelectedItemMenu(path);
  };

  const checkMenuLink = (item: any, parrentItem = null) => {
    const currentLocation = history.location.pathname;
    if (item.link.replace('/api', '') === currentLocation) {
      setSelectedItemMenu([item.key]);
      if (parrentItem) {
        setOpenSubMenu([parrentItem.key]);
      }
      return null;
    }
    return null;
  };

  const handleNavigation = () => {
    if (menuItems.length > 0) {
      menuItems.forEach((item) => {
        if (item.link) {
          checkMenuLink(item);
        }

        if (item.items) {
          item.items.forEach((itemChild) => {
            if (itemChild.link) {
              checkMenuLink(itemChild);
            }

            if (itemChild.hasSubmenu) {
              itemChild.subItems?.forEach((subItem) => {
                if (subItem.link) {
                  checkMenuLink(subItem, itemChild);
                }
              });
            }
          });
        }
      });
    }
  };

  React.useEffect(() => {
    history.listen((a: any, b: any) => {
      handleNavigation();
    });

    handleNavigation();
  }, [menuItems, history]);

  return (
    <div className="side-nav app-layout side-manu-width">
      <NavMenu
        className="h-100 static-menu"
        mode="inline"
        inlineCollapsed={isMenuCollapsed}
        openKeys={openSubMenu}
        selectedKeys={selectedItemMenu}
        onOpenChange={(arrayKey) => setOpenSubMenu(arrayKey)}
        onSelect={(arrayKey) => onChangeNavTab(arrayKey.keyPath, arrayKey)}
      >
        { buildMenu(menuItems) }
      </NavMenu>
    </div>
  );
};

export default Menu;
