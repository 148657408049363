import React from 'react';

// components
import { Menu, Dropdown } from 'antd';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { appSettings } from 'assets/mocked-data';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from 'hooks/use-local-storage';

// store
import { getLanguage } from 'redux/selectors';
import { setLanguage } from 'redux/slices/configSlice';

// types;
import { LocalStorageEnum } from 'types/enumes';

const LanguageConfiguration: React.FC = () => {
  const currentLanguage: string = useSelector(getLanguage);
  const dispatch = useDispatch();
  const { setItem } = useLocalStorage();

  const handleChangeLanguage = (lang: string): void => {
    dispatch(setLanguage(lang));
    setItem(LocalStorageEnum.APP_LANGUAGE, lang);
  };

  const SelectedLanguage = () => (
    <div className="d-flex align-items-center">
      <img
        style={{ maxWidth: '20px', marginRight: '10px' }}
        src={`/img/flags/${currentLanguage}.png`}
        alt={currentLanguage}
      />
      <div className="font-weight-semibold px-2">
        {currentLanguage.toUpperCase()}
        <DownOutlined className="font-size-xs" style={{ marginLeft: '10px' }} />
      </div>
    </div>
  );

  const languageOption = (
    <Menu>
      {appSettings.languages.length > 0 ? (
        appSettings.languages.map((lang: string) => (
          <Menu.Item
            key={lang}
            className="ant-dropdown-menu-item-active"
            onClick={() => handleChangeLanguage(lang)}
          >
            <span className="d-flex justify-content-between align-items-center">
              <div>
                <img style={{ maxWidth: '20px', marginRight: '10px' }} src={`/img/flags/${lang}.png`} alt={lang} />
                <span className="font-weight-normal">
                  {lang.toUpperCase()}
                </span>
              </div>
              {currentLanguage === lang ? <CheckOutlined className="text-success" /> : null}
            </span>
          </Menu.Item>
        ))
      ) : null}
    </Menu>
  );

  return (
    <Dropdown placement="bottomRight" overlay={languageOption} trigger={['click']}>
      <a href="/" onClick={(e) => e.preventDefault()}>
        <SelectedLanguage />
      </a>
    </Dropdown>
  );
};

export default LanguageConfiguration;
