import styled from 'styled-components';

type Props = {
  // backgroundColorPagination: string
}

const WrapperIconsStyled = styled.div<Props>`
  display: inline;
  border: 2px solid #a2aab0;
  margin-right: 16px;
  font-size: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
`;

export { WrapperIconsStyled };
