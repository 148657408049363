/* eslint-disable no-param-reassign */
import {
  createAsyncThunk, createSlice, PayloadAction, current,
} from '@reduxjs/toolkit';

const DATA_TABLE_SLICE_NAME = 'DATA_TABLE_SLICE';

const initialState: any = {
  url: null,
  pathFilterReset: null,
  pathFilterIcon: null,
  pathAddButton: null,
  pathExportButton: null,
  requestExportButton: null,
};

export const dataTableSlice = createSlice({
  name: DATA_TABLE_SLICE_NAME,
  initialState,
  reducers: {
    setDataTableUrl: (state: any, action: PayloadAction<any>) => {
      state.url = action.payload;
    },
    setPathFilterReset: (state: any, action: PayloadAction<any>) => {
      state.pathFilterReset = action.payload;
    },
    setPathAddButton: (state: any, action: PayloadAction<any>) => {
      state.pathAddButton = action.payload;
    },
    setPathExportButton: (state: any, action: PayloadAction<any>) => {
      state.pathExportButton = action.payload;
    },
    setRequestExportButton: (state: any, action: PayloadAction<any>) => {
      state.requestExportButton = action.payload;
    },
    setPathFilterIcon: (state: any, action: PayloadAction<any>) => {
      state.pathFilterIcon = action.payload;
    },
  },
});

export const {
  setDataTableUrl,
  setPathFilterReset,
  setPathFilterIcon,
  setPathAddButton,
  setPathExportButton,
  setRequestExportButton,
} = dataTableSlice.actions;
export default dataTableSlice.reducer;
