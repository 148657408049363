import React from 'react';
import { Switch, Route } from 'react-router-dom';

// components
import { Layout } from 'antd';
import Sidebar from 'views/layouts/sidebar';
import Footer from 'views/layouts/footer';

// dynamic components
const Dashboard = React.lazy(() => import('views/pages/dashboard'));
const NotFound = React.lazy(() => import('views/pages/not-found-page'));

const ContentLayout: React.FC = () => (
  <Layout.Content>
    <Sidebar />
    <div className="app-layout content-width">
      <Layout className="app-content">
        <Layout.Content>
          <React.Suspense fallback={null}>
            <Switch>
              <Route
                path="/"
                exact
                component={({ match, history, location }: any) => <Dashboard match={match} history={history} location={location} />}
              />
              <Route
                path="/:id"
                exact
                component={({ match, history, location }: any) => <Dashboard match={match} history={history} location={location} />}
              />
              <Route
                path="/:id/:id"
                exact
                component={({ match, history, location }: any) => <Dashboard match={match} history={history} location={location} />}
              />
              <Route
                path="/:id/:id/:id"
                exact
                component={({ match, history, location }: any) => <Dashboard match={match} history={history} location={location} />}
              />
              <Route path="*" exact component={NotFound} />
            </Switch>
          </React.Suspense>
        </Layout.Content>
      </Layout>
      <Footer />
    </div>
  </Layout.Content>
);

export default ContentLayout;
