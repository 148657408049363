export enum CoreDataType {
	GRID = 'grid',
	FORM = 'form',
}

export enum ThemeTypesEnum {
	LIGHT = 'light',
	DARK = 'dark',
}

export enum SlotTypesEnum {
	BLOCK = 'block',
}

export enum LocalStorageEnum {
	APP_LANGUAGE = 'APP_LANGUAGE',
	ACCESS_TOKEN = 'ACCESS_TOKEN',
}

export enum AppRoutes {
	APP_ROOT = '/',
	APP = '/app',
	LOGIN = '/login',
	RECOVERY_PASSWORD = '/recover-password',
	RECOVERY_PASSWORD_TOKEN = '/recover-password/token/:token',
}

export enum ApiRoutes {
	LOGIN = '/auth/login',
	LOGOUT = '/auth/logout',
	TOKEN_REFRESH = 'api/auth/refresh-token',
	RECOVERY_PASSWORD = '/recover-password',
	RECOVERY_PASSWORD_TOKEN = '/recover-password/token/:token',
	RESET_PASSWORD = '/reset-password',
	SYSTEM_CONFIG = 'system/config',
	FILES_CONFIG = 'file/upload/:hash',
	FILES_UPLOAD = 'api/file/upload'
}

export enum MessageContent {
	SAVE_SUCCESS = 'Dane zapisane.',
	SAVE_FAIL = 'Błąd podczas zapisu danych.',
	GET_FAIL = 'Błąd podczas pobierania danych.',
	GET_FAIL_SERVER = 'Błąd servera.',
	DELETE_SUCCESS = 'Element został usunięty.',
	ACCEPT_SUCCESS = 'Element został zaakceptowany.',
	DELETE_FAIL = 'Błąd podczas usuwania elementu.',
	RELOAD_FAIL = 'Błąd wczytywania formularza.',
	FILE_SAVE_SUCCESS = 'Plik został dodany. Aby trwale zapisać plik, zapisz formularz.',
	FILE_SAVE_FAIL = 'Błąd podczas dodawania pliku.',
	FILE_REMOVE_SUCCESS = 'Plik został usunięty. Aby trwale usnąć plik, zapisz formularz.',
	MODAL_SHORTCODE_SUCCESS = 'Element został osadzony poprawnie.',
	MODAL_SHORTCODE_FAIL = 'Błąd podczas osadzanie elementu.',
	RELOAD_DATA_FAIL = 'Błąd ponownego wczytania danych'
}

export enum ActionButtonType {
	CANCEL_BUTTON = 'cancelFormButton',
	DELETE_BUTTON_AND_BACK = 'deleteAndBackButton',
	CANCEL_MODAL_BUTTON = 'closeFormButton',
	SAVE_BUTTON = 'saveFormButton',
	SAVE_AND_BACK_BUTTON = 'saveFormAndBackButton',
	PRINT_BUTTON = 'printFormButton',
}

export enum ConfirmRemoveModal {
	CONFIRM_REMOVE_TITLE = 'Potwierdź usunięcie pliku.',
	CONFIRM_REMOVE_OK = 'Usuń',
	CONFIRM_REMOVE_CANCEL = 'Anuluj'
}

export enum FormEnumMessages {
	ITEM_NOT_FOUND = 'Wystąpił problem z elementem formularza.',
	ITEM_NOT_FOUND_LABEL = 'Bład',
	INCORRECT_EMAIL_ADRESS = 'Podany adres e-mail jest niepoprawny.',
	NO_ITEM = 'Brak elementów.',
	TIMEPICKER_PLACEHOLDER = 'Wybierz godzinę',
	FILE_SAVING = 'Zapisywanie...',
	UPLOADER_TEXT = 'Kliknij lub przęciągnij i upuść plik tutaj.',
	UPLOADER_AVAILABLE_EXTENSIONS = 'Akceptowane formaty plików:',
	SHORTCODE_EMBED = 'Osadzenie elementu',
	FORM_CONFIR_MESSAGE = 'Czy chcesz wrócić i porzucić zmiany wprowadzone w formularzu?',
	FORM_ERROR_VALIDATION_MESSAGE = 'Uzupełnij wszystkie wymagane pola w formularzu',
	FORM_PROCEED_CONFIRM_MESSAGE = 'Czy chcesz przejśc dalej i porzucić zmiany wprowadzone w formularzu?',
	FORM_CONFIRM_DELETE_MESSAGE = 'Czy na pewno chcesz usunąć?'
}

export enum FormActionButtons {
	BUTTON_SAVE = 'Zapisz',
	BUTTON_SAVE_AND_BACK = 'Zapisz i wróć',
	BUTTON_BACK = 'Wróć',
	BUTTON_CONFIRM = 'Potwierdzam',
	BUTTON_CANCEL = 'Anuluj',
	BUTTON_OK = 'OK',
	BUTTON_PRINT = 'Drukuj'
}
