/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// components
import i18n from 'core/lang';

// services
import APIService from 'services/api';

// types
import { IConfigSlice } from 'types/store';
import { ApiRoutes } from 'types/enumes';

// mocked
import { slotsConfigData } from 'assets/mocked-data';
import { IAppSettings } from 'types/core';

const CONFIG_SLICE_NAME = 'CONFIG_SLICE';

const initialState: IConfigSlice = {
  user: {},
  isMenuCollapsed: false,
  currentLanguage: 'pl',
  menu: [],
  slots: slotsConfigData,
  settings: {} as IAppSettings,
};

export const getAppConfiguration = createAsyncThunk(
  'config/getAppConfiguration',
  async (): Promise<any> => {
    try {
      const res = await APIService.get<{ menu: any; slots: any; settings: any; }>(ApiRoutes.SYSTEM_CONFIG);
      const { data } = res;
      return { ...data };
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

export const navSlice = createSlice({
  name: CONFIG_SLICE_NAME,
  initialState,
  reducers: {
    toggleMenuCollapsing: (state: IConfigSlice, action: PayloadAction<boolean>) => {
      state.isMenuCollapsed = action.payload;
    },
    setLanguage: (state: IConfigSlice, action: PayloadAction<string>) => {
      state.currentLanguage = action.payload;
      i18n.changeLanguage(action.payload);
    },
    setAppConfig: (state: IConfigSlice, action: PayloadAction<{ data: any, slots: any }>) => {
      state.menu = action.payload.data;
      state.slots = action.payload.slots;
    },
    setUser: (state: IConfigSlice, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [getAppConfiguration.fulfilled]: (state, action: PayloadAction<{ menu: any; slots: any; settings: any; }>) => {
      const { menu, slots, settings } = action.payload;
      state.menu = menu;
      state.slots = slots;
      state.settings = settings;
    },
  },
});

export const {
  toggleMenuCollapsing, setLanguage, setUser,
} = navSlice.actions;
export default navSlice.reducer;
