import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { IResponse, apiRoutes } from 'types/core';

import { ApiRoutes } from 'types/enumes';

import { apiInstance } from './config';
import { API_HOST } from '../../core/appConfig';

/**
 * Generic GET api call
 * @param path
 * @param config
 */
const get = async <T>(path: apiRoutes, config?: AxiosRequestConfig): Promise<IResponse<T>> | Promise<any> => {
  let url = path;

  if (url.indexOf('/api') == 0) {
    url = path.replace('/api', '');
  }

  const { data }: AxiosResponse<IResponse<T>> = await apiInstance.get(url, config);
  return data;
};

/**
 * Generic POST api call
 * @param path
 * @param bodyData
 * @param config
 */
const post = async <T>(path: apiRoutes, bodyData? :any, config?: AxiosRequestConfig): Promise<IResponse<T>> => {
  path = path.replace('/api/', '/');
  const { data } : AxiosResponse<IResponse<T>> = await apiInstance.post(path, bodyData, config);
  return data;
};

/**
 * Generic PATCH api call
 * @param path
 * @param bodyData
 * @param config
 */
const patch = async <T>(path: apiRoutes, bodyData? :any, config?: AxiosRequestConfig): Promise<IResponse<T>> => {
  path = path.replace('/api/', '/');
  const { data } : AxiosResponse<IResponse<T>> = await apiInstance.patch(path, bodyData, config);
  return data;
};

/**
 * Generic DELETE api call
 * @param path
 * @param bodyData
 */
const remove = async <T>(path: apiRoutes, bodyData? :any, config?: AxiosRequestConfig): Promise<IResponse<T>> => {
  path = path.replace('/api/', '/');
  const { data } : AxiosResponse<IResponse<T>> = await apiInstance.delete(path);
  return data;
};

const getFileDataFromBackend = async (hash: string): Promise<any> => {
  const path = ApiRoutes.FILES_CONFIG.replace(':hash', hash);
  const { data } = await apiInstance.get(path);
  return data;
};

const removeFileDataFromBackend = async (hash: string): Promise<any> => {
  const path = ApiRoutes.FILES_CONFIG.replace(':hash', hash);
  const { data } = await apiInstance.delete(path);
  return data;
};

const getShortCodeData = async (path: string): Promise<any> => {
  const { data } = await apiInstance.get(path);
  return data;
};

const APIService = {
  post,
  get,
  patch,
  remove,
  getFileDataFromBackend,
  removeFileDataFromBackend,
  getShortCodeData,
};

export default APIService;
