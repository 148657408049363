import React from 'react';

// components
import { Menu as NavMenu, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Icons from 'views/components/icons';

// types
import { IMenuItem, IMenuGroup } from 'types/core';

const useMenuBuilder = (): { buildMenu: (groups: IMenuGroup[]) => any; } => {
  const drawSingleMenuItem = (item: IMenuItem): React.ReactElement => {
    const {
      id, key, text, link = '', icon,
    } = item;
    const modifiedLink = link.replace('/api', '');
    return (
      <Menu.Item key={key.toString()}>
        <Link to={modifiedLink}>
          <Icons icon={icon} name={text} />
          <span>{text}</span>
        </Link>
      </Menu.Item>
    );
  };

  const renderMenuItems = (items: IMenuItem[]): React.ReactElement[] => (
    items.map((item: IMenuItem) => {
      const { hasSubmenu } = item;
      	if (!hasSubmenu) {
        return drawSingleMenuItem(item);
      }
      	return drawSubMenu(item);
    })
  );

  const drawMenuGroups = (groups: IMenuGroup[]) => (
    groups.map((group: IMenuGroup) => {
      const { id, title, items } = group;
      return (
        <NavMenu.ItemGroup
          key={id}
          title={title}
        >
          { items && renderMenuItems(items) }
        </NavMenu.ItemGroup>
      );
    })
  );

  const drawSubMenu = (item: IMenuItem): React.ReactElement => {
    const {
      id, text, icon, subItems,
    } = item;
    return (
      <Menu.SubMenu
        data-class="nav-item"
        key={id}
        title={(
          <span>
            <Icons icon={icon} name={text} />
            <span>{text}</span>
          </span>
      )}
      >
        { subItems && renderMenuItems(subItems) }
      </Menu.SubMenu>
    );
  };

  return {
    buildMenu: drawMenuGroups,
  };
};

export default useMenuBuilder;
