import { IAppSettings } from 'types/core';
import { SlotTypesEnum } from 'types/enumes';

const menuData = [
  {
    id: 'xhsAsj2',
    title: 'Category 1',
    items: [
      {
        id: 'xhjasj2',
        icon: 'PieChartOutlined',
        text: 'Page 1',
        link: '/app/page-1',
        hasSubmenu: false,
      },
      {
        id: '00xs1asj2',
        icon: 'DesktopOutlined',
        text: 'Page 2',
        link: '/app/page-2',
        hasSubmenu: false,
      },
    ],
  },
  {
    id: '19sAcc2',
    title: 'Category 2',
    items: [
      {
        id: 'e0xs1as92',
        icon: 'InboxOutlined',
        text: 'Page 3',
        link: '/app/page-3',
        hasSubmenu: false,
      },
      {
        id: 'yy001a8j2',
        icon: 'MailOutlined',
        text: 'Submenu',
        hasSubmenu: true,
        subItems: [
          {
            id: 'yyzz1a855',
            icon: 'DesktopOutlined',
            text: 'Page 4',
            link: '/app/page-4',
            hasSubmenu: false,
          },
          {
            id: '3y221a855',
            icon: 'DesktopOutlined',
            text: 'Page 5',
            link: '/app/page-5',
            hasSubmenu: false,
          },
          {
            id: '15h1XyZ2',
            icon: 'InboxOutlined',
            text: 'Submenu 2',
            hasSubmenu: true,
            subItems: [
              {
                id: 'y4ll1a8jY',
                icon: 'DesktopOutlined',
                text: 'Page 6',
                link: '/app/page-6',
                hasSubmenu: false,
              },
            ],
          },
        ],
      },
    ],
  },
];

const slotsConfigData = [
  {
    id: 'topRight',
    layout: { data: [], type: SlotTypesEnum.BLOCK },
    widgets: [
      {
        component: 'HeaderProfile',
        componentData: [],
        active: true,
        priority: 100,
        forcedPriority: 100,
      },
      {
        component: 'HeaderPanel',
        componentData: [],
        active: true,
        priority: 100,
        forcedPriority: 100,
      },
    ],
  },
];

const appSettings: IAppSettings = {
  changeLanguage: true,
  languages: ['en', 'pl'],
  systemName: '',
};

export {
  menuData,
  slotsConfigData,
  appSettings,
};
